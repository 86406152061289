import request from './request'

export const getSubscriptionAddress = (params) => {
  return request({
    url: 'config/getSubscriptionAddress',
    params
  })
}

export const updateSubscriptionAddress = (params) => {
  return request({
    url: 'config/updateSubscriptionAddress',
    method: 'POST',
    params
  })
}
