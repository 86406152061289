import request from './request'

export const getInvestmentAddress = (params) => {
  return request({
    url: 'config/getInvestmentAddress',
    params
  })
}

export const updateInvestmentAddress = (params) => {
  return request({
    url: 'config/updateInvestmentAddress',
    method: 'POST',
    params
  })
}
