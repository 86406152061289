import request from './request'

export const getFeesAddress = (params) => {
  return request({
    url: 'config/getFeesAddress',
    params
  })
}

export const updateFeesAddress = (params) => {
  return request({
    url: 'config/updateFeesAddress',
    method: 'POST',
    params
  })
}
