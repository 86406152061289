import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1e857fca"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "form-box"
};
const _hoisted_2 = {
  class: "form-box"
};
const _hoisted_3 = {
  class: "form-box"
};
import { getSubscriptionAddress, updateSubscriptionAddress } from '@/api/subscription_address';
import { getInvestmentAddress, updateInvestmentAddress } from '@/api/investment_address';
import { getFeesAddress, updateFeesAddress } from '@/api/fees_address';
import { ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  __name: 'address_config',
  setup(__props) {
    const formRef1 = ref(null);
    const formRef2 = ref(null);
    const formRef3 = ref(null);
    const rules1 = ref({
      subscriptionAddress: [{
        required: true,
        message: '请输入机器人充值地址',
        trigger: 'blur'
      }]
    });
    const rules2 = ref({
      investmentAddress: [{
        required: true,
        message: '请输入托管充值地址',
        trigger: 'blur'
      }]
    });
    const rules3 = ref({
      investmentAddress: [{
        required: true,
        message: '请输入手续费接收地址',
        trigger: 'blur'
      }]
    });
    const form1 = ref({
      subscriptionAddress: ''
    });
    const form2 = ref({
      investmentAddress: ''
    });
    const form3 = ref({
      investmentAddress: ''
    });
    const formData1 = ref({
      address: ''
    });
    const formData2 = ref({
      address: ''
    });
    const formData3 = ref({
      address: ''
    });
    const confirmModification1 = async () => {
      formRef1.value.validate(async valid => {
        if (valid) {
          ElMessageBox.confirm('是否确认修改机器人充值地址?', '警告', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const {
              subscriptionAddress
            } = form1.value;
            formData1.value.address = subscriptionAddress;
            try {
              await updateSubscriptionAddress(formData1.value);
            } catch (error) {
              console.error('Failed to update and initialize address', error);
            }
            ElMessage({
              type: 'success',
              message: '修改成功'
            });
          }).finally(async () => {
            await initAddress1();
          });
        }
      });
    };
    const confirmModification2 = async () => {
      formRef2.value.validate(async valid => {
        if (valid) {
          ElMessageBox.confirm('是否确认托管充值地址?', '警告', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const {
              investmentAddress
            } = form2.value;
            formData2.value.address = investmentAddress;
            try {
              await updateInvestmentAddress(formData2.value);
            } catch (error) {
              console.error('Failed to update and initialize address', error);
            }
            ElMessage({
              type: 'success',
              message: '修改成功'
            });
          }).finally(async () => {
            await initAddress2();
          });
        }
      });
    };
    const confirmModification3 = async () => {
      formRef3.value.validate(async valid => {
        if (valid) {
          ElMessageBox.confirm('是否确认手续费接收地址?', '警告', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const {
              investmentAddress
            } = form3.value;
            formData3.value.address = investmentAddress;
            try {
              await updateFeesAddress(formData3.value);
            } catch (error) {
              console.error('Failed to update and initialize address', error);
            }
            ElMessage({
              type: 'success',
              message: '修改成功'
            });
          }).finally(async () => {
            await initAddress3();
          });
        }
      });
    };
    const initAddress1 = async () => {
      const response = await getSubscriptionAddress();
      form1.value.subscriptionAddress = response.Address;
    };
    initAddress1();
    const initAddress2 = async () => {
      const response = await getInvestmentAddress();
      form2.value.investmentAddress = response.Address;
    };
    initAddress2();
    const initAddress3 = async () => {
      const response = await getFeesAddress();
      form3.value.investmentAddress = response.Address;
    };
    initAddress3();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_form, {
        ref_key: "formRef1",
        ref: formRef1,
        model: form1.value,
        rules: rules1.value,
        "label-width": "130px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "机器人充值地址:",
          prop: "subscriptionAddress"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form1.value.subscriptionAddress,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form1.value.subscriptionAddress = $event),
            style: {
              "width": "400px"
            }
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            onClick: confirmModification1
          }, {
            default: _withCtx(() => [_createTextVNode("确认修改")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form, {
        ref_key: "formRef2",
        ref: formRef2,
        model: form2.value,
        rules: rules2.value,
        "label-width": "130px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "托管充值地址:",
          prop: "investmentAddress"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form2.value.investmentAddress,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form2.value.investmentAddress = $event),
            style: {
              "width": "400px"
            }
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            onClick: confirmModification2
          }, {
            default: _withCtx(() => [_createTextVNode("确认修改")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_form, {
        ref_key: "formRef3",
        ref: formRef3,
        model: form3.value,
        rules: rules3.value,
        "label-width": "130px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "手续费接收地址:",
          prop: "investmentAddress"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form3.value.investmentAddress,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form3.value.investmentAddress = $event),
            style: {
              "width": "400px"
            }
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            onClick: confirmModification3
          }, {
            default: _withCtx(() => [_createTextVNode("确认修改")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules"])])]);
    };
  }
};